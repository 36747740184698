import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


import Bootstrap from '../css/bootstrap.grid.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <section className="custom-container privacy">
    <h1>Privacy Policy</h1>
    <div className="container">
        <p>In questa sezione si descrivono le modalità gestione dei dati personali che l’utente decide di fornire attraverso l’invio del modulo di contatto presente sul sito <a href="https://www.ristorante-ilcacciatore.it/">www.ristorante-ilcacciatore.it</a>, sito di proprietà di Michele Lombardi</p>
        <p>Si tratta di informazioni rese agli utenti in relazione alle norme previste dal Regolamento UE 2016/679 ed in particolare degli artt. 12-23 dello stesso Regolamento.</p>
        <h3>Titolare del trattamento</h3>
        <p>Il titolare del trattamento dei dati personali attraverso <a href="https://www.ristorante-ilcacciatore.it/">www.ristorante-ilcacciatore.it</a> è:</p>
            <ul>
                <li>Michele Lombardi</li>
                <li>P.Iva </li>
                <li>C.da San Marco 18</li>
                <li>86012 - Cercemaggiore (CB)</li>
            </ul>
        <h3>Dati raccolti con il modulo di contatto</h3>
        <p>Compilando il modulo di contatto l'utente:</p>
            <ul>
                <li>Comunica il proprio nome</li>
                <li>Comunica l'indirizzo email al quale vuole essere contattato</li>
                <li>Scrive un messaggio da inviare a ristoranteilcacciatore@gmail.com</li>
            </ul>
        <h3>Finalità del trattamento</h3>
        <p>Il nome, l’indirizzo email indicato dall’utente al momento della compilazione del modulo di contatto e le informazioni inserite dall’utente nel corpo del messaggio sono utilizzate esclusivamente per rispondere alla richiesta dell’utente stesso.</p>
        <h3>Destinatari dei dati personali</h3>
        <p>I dati personali raccolti da Michele Lombardi tramite <a href="https://www.ristorante-ilcacciatore.it/">www.ristorante-ilcacciatore.it</a> potranno essere trattati da soggetti coinvolti nell’organizzazione del sito (come per esempio personale amministrativo, commerciale, marketing, legali, amministratori di sistema) o da personale esterno (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) di volta in volta nominate, se necessario, Responsabili del trattamento a norma dell’art. 4 n.8) GDPR che definisce come “responsabile del trattamento” la persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che tratta dati personali per conto del titolare del trattamento.</p>
        <p>In ogni caso ogni utente potrà sempre richiedere al Titolare del trattamento l’elenco aggiornato dei Responsabili del trattamento con le modalità di cui alla sezione relativa ai diritti dell’utente (ossia mediante invio di comunicazione mail a ristoranteilcacciatore@gmail.com)</p>
        <p>Ad eccezione delle ipotesi appena indicate i dati personali dell’utente non vengono comunicati a terzi salvo:</p>
            <ul>
                <li>L’utente abbia rilasciato il proprio consenso espresso alla comunicazione</li>
                <li>La comunicazione sia necessaria per fornire il servizio o il prodotto richiesto dall’utente</li>
                <li>La comunicazione sia necessaria in riferimento a terzi che lcollaborano con Michele Lombardi per fornire il servizio o il prodotto richiesto dall’utente. (in questo caso verranno comunicati solo i dati indispensabili per la prestazione del servizio o la fornitura del prodotto ed alle stesse è fatto divieto di utilizzare gli stessi dati per finalità diverse).</li>
                <li>Sia richiesto dalla legge</li>
            </ul>
        <h3>Periodo di conservazione</h3>
        <p>Le informazioni date dall’utente con la compilazione del modulo di contatto (nome, indirizzo email, testo del messaggio) verranno trattati e conservati per il tempo strettamente necessario per la realizzazione della finalità per cui è stato raccolto, ossia per l’invio della risposta al quesito sottoposto.</p>
        <h3>Diritto di recesso</h3>
        <p>L’interessato ha il diritto di chiedere al Titolare del trattamento se sia in corso un trattamento di dati personali che lo riguardano e in caso affermativo di ottenere l’accesso agli stessi e le seguenti informazioni:</p>
            <ol>
                <li>Le finalità del trattamento</li>
                <li>Le categorie di dati in questione</li>
                <li>I destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali</li>
                <li>Quando possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo</li>
                <li>L’esistenza del diritto dell’interessato di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento</li>
                <li>Il diritto di proporre reclamo a un’autorità di controllo</li>
                <li>Qualora i dati non siano raccolti presso l’interessato, tutte le informazioni disponibili sulla loro origine</li>
                <li>L’esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all’articolo 22, paragrafi 1 e 4, e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l’importanza e le conseguenze previste di tale trattamento per l’interessato</li>
            </ol>
        <p>Qualora i dati personali siano trasferiti a un paese terzo o a un’organizzazione internazionale, l’interessato ha il diritto di essere informato dell’esistenza di garanzie adeguate ai sensi dell’articolo 46 relative al trasferimento</p>
        <p>Michele Lombardi specifica che:</p>
            <ul>
                <li>Non comunica i dati personali degli utenti a organizzazioni internazionali o a paesi terzi</li>
                <li>L’interessato ha in ogni momento la possibilità di chiedere la rettifica o la cancellazione dei propri dati personali secondo le modalità indicate nella relativa sezione (ossia mediante invio di comunicazione mail a ristoranteilcacciatore@gmail.com )</li>
                <li>I dati personali non vengono raccolti con procedimenti completamente automatizzati, ma è sempre necessario il consenso dell’utente che inserisce i propri dati nei form predisposti sul sito</li>
            </ul>
        <h3>Diritto alla cancellazione e diritto di rettifica</h3>
        <p>L’interessato ha il diritto di ottenere dal titolare del trattamento:</p>
            <ul>
                <li>La rettifica dei dati, qualora non siano corretti</li>
                <li>La cancellazione dei propri dati personali</li>
            </ul>
        <p>Per l’esercizio di questo diritto è possibile inviare una richiesta scritta a ristoranteilcacciatore@gmail.com</p>
        
        <p>Il titolare del trattamento provvederà, senza ritardo, nel pieno rispetto dell’art. 17 del Regolamento europeo alla cancellazione richiesta.</p>
        <h3>Diritto di limitazione del trattamento</h3>
        <p>L’interessato ha il diritto di ottenere dal titolare del trattamento la limitazione del trattamento stesso nelle seguenti ipotesi:</p>
            <ul>
                <li>L’interessato contesta l’esattezza dei propri dati: per il tempo necessario al titolare del trattamento per verificarne l’esattezza</li>
                <li>Trattamento illecito: l’interessato si oppone alla cancellazione e chiede che ne venga limitato l’utilizzo</li>
                <li>L’interessato si oppone al trattamento a norma dell’art. 21 paragrafo 1 in attesa della verifica in merito all’eventuale prevalenza dei motivi legittimi del titolare del trattamento rispetto a quelli dell’interessato</li>
            </ul>
    </div>
    </section>
  </Layout>
)

export default IndexPage
